import React, { useState, useRef, useEffect } from 'react';
import { CustomLoader } from '../All Components/customLoader';
import axios from 'axios'
import 'react-h5-audio-player/lib/styles.css';
import { REACT_APP_BASE_URL } from '../../../src/config'
import { FaCcPaypal } from 'react-icons/fa';
import { showErrorToast } from '../../commonToast';

function MergePlayAudio({ audioFile1, audioFile2, volumePercentage1, volumePercentage2, setShowMergeButton }) {
  const audioPlayer1 = useRef(null);
  const audioPlayer2 = useRef(null);
  const [mergedAudioUrl1, setMergedAudioUrl1] = useState(null);
  const [mergedAudioUrl, setMergedAudioUrl] = useState(null);
  const [isMerging, setIsMerging] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume1, setVolume1] = useState(volumePercentage1);
  const [volume2, setVolume2] = useState(volumePercentage2);
  const [isProcessing, setIsProcessing] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponError, setCouponError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(''); 
  };
  const handleCouponChange = (e) => {
    setCouponCode(e.target.value);
    if (couponError) {
      setCouponError('');
    }
  };
  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
    if (audioPlayer1.current) {
      if (!isPlaying) {
        audioPlayer1.current.play();
      } else {
        audioPlayer1.current.pause();
      }
    }
    if (audioPlayer2.current) {
      if (!isPlaying) {
        audioPlayer2.current.play();
      } else {
        audioPlayer2.current.pause();
      }
    }
  };
  const audioContextRef = useRef(null);

  useEffect(() => {
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
  }, []);

  const mergeAudio = async (event) => {
    event.preventDefault(); 
    if (!audioFile1 || !audioFile2) {
      showErrorToast('Error: Please select both audio files.');
      return;
    }

    setIsMerging(true);

    try {
      const audioFileName = audioFile2 && audioFile2
        ? audioFile2.split('/').pop()
        : null;

      if (!audioFileName) {
        throw new Error('audioFile2 does not have a valid src property.');
      }

      const response = await axios.get(`${REACT_APP_BASE_URL}/merge-preview`, {
        params: {
          audioFileUrl1: audioFile1.title.replace(/\+/g, ' '), 
          audioFile2: audioFileName, 
        },
        paramsSerializer: params => {
          return Object.keys(params)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');
        }
      });

      if (response?.data) {
        console.log('responseresponse', response)
        setMergedAudioUrl(response?.data.outputFiles?.predefined);
        setMergedAudioUrl1(response?.data.outputFiles?.input);
      } else {
        throw new Error('No URL returned from the merge audio API.');
      }
    } catch (error) {
      console.error('Error during audio merging:', error);
    } finally {
      setIsMerging(false);
    }
  };



  const handleVolumeChange1 = (e) => {
    setVolume1(e.target.value);
    if (audioPlayer1.current) {
      audioPlayer1.current.volume = e.target.value / 100;
    }
  };
  const handleVolumeChange2 = (e) => {
    setVolume2(e.target.value);
    if (audioPlayer2.current) {
      audioPlayer2.current.volume = e.target.value / 100;
    }
  };

  const handleSeek = (player, time) => {
    if (player === 'player1' && audioPlayer2.current) {
      if (Math.abs(audioPlayer2.current.currentTime - time) > 0.1) { 
        audioPlayer2.current.currentTime = time;
      }
    } else if (player === 'player2' && audioPlayer1.current) {
      if (Math.abs(audioPlayer1.current.currentTime - time) > 0.1) {
        audioPlayer1.current.currentTime = time;
      }
    }
  };

  const handlepayment = async () => {
    if (!email) {
      setEmailError('Email is required');
      return;
    }
    let couponResponse = null;
    if (couponCode) {
      try {
        couponResponse = await axios.post(`${REACT_APP_BASE_URL}/coupon/check-coupon`, { couponCode });
        if (!couponResponse.data.success) {
          setCouponError('Invalid coupon code.');
          setIsProcessing(false);
          return;
        }
      } catch (error) {
        console.error("Error checking coupon code:", error);
        setCouponError('Error validating coupon code.');
        setIsProcessing(false);
        return;
      }
    }
    setIsProcessing(true);
    const formData = new FormData();
    formData.append('audioFile1', audioFile1.title);
    formData.append('audioFile2', audioFile2);
    formData.append('volume1', volume1);
    formData.append('volume2', volume2);
    formData.append('couponCode', couponCode);
    formData.append('email', email);
  
    try {
      const sessionResponse = await axios.post(`${REACT_APP_BASE_URL}/session`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      if (couponResponse?.data?.data?.discountPercentage === 100) {
        window.location.href = `/success?sessionId=${sessionResponse.data.session._id}`;
        return;
      }
  
      if (sessionResponse.data?.session?._id) {
        localStorage.setItem('sessionId', sessionResponse.data.session._id);
  
        const paymentResponse = await axios.post(`${REACT_APP_BASE_URL}/paypal-payment`, {
          sessionId: sessionResponse.data.session._id,
          couponCode,
          email
        });
  
        window.location.href = paymentResponse.data;
      } else {
        throw new Error('Session creation failed. No session ID returned.');
      }
    } catch (error) {
      console.error("Error during payment processing:", error.response?.data || error.message);
    } finally {
      setIsProcessing(false);
    }

  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      {!mergedAudioUrl && (
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          {isMerging && (
            <div style={{
              color: '#555',
              fontSize: '16px',
              fontWeight: 'bold',
              marginBottom: '10px',
              animation: 'fadeIn 0.5s'
            }}>
              Please wait, it will take a bit time...
            </div>
          )}
          <button
            style={{
              background: 'linear-gradient(to right, #b5eaef, #ede38f, #f492ed)',
              color: 'black',
              padding: '10px 20px',
              borderRadius: '32px',
              cursor: 'pointer',
              marginBottom: '30px',
              width: '250px',
              fontWeight: 'bold',
              fontSize: '20px',
              opacity: isMerging ? 0.5 : 1,
              pointerEvents: isMerging ? 'none' : 'auto',
              // position: 'relative',
            }}
            onClick={mergeAudio}
            disabled={isMerging}
          >
            {isMerging ? (
              <>
                <span >Merging...</span>
                {/* <CustomLoader size={20} color="black" /> */}
              </>
            ) : (
              'Merge Audio'
            )}
          </button>
        </div>
      )}

      {mergedAudioUrl && (
        <div style={{
          marginLeft: '30px',
          display: 'flex',
          justifyContent: 'center',
        }}>
          <p
            style={{
              marginTop: '20px',
              borderRadius: '20px',
              padding: '10px 20px', // Adjusted padding
              textAlign: 'center',
              maxWidth: '80%',
              color: 'black',
              backgroundColor: '#f9f9f9'
            }}
          >
            <strong>This is only a preview</strong> of the audio file. Here you can adjust the audio of both your own audio and the manifestation soundtrack. Happy with the result? Click the ‘Pay now and create full affirmation audio’-button to generate the full 30 minute merged audio file. (This can take a few minutes) You have to pay before you can download.
          </p>
        </div>
      )}
      {mergedAudioUrl && (
   <div className="audio-player-wrapper flex flex-col items-center w-full px-4">
   {/* Centered Audio Player */}
   <audio
     ref={audioPlayer1}
     src={mergedAudioUrl}
     controls
     className="w-4/5 md:w-3/5 outline-none rounded-md mb-4"
     onPlay={handlePlayPause}
     onPause={handlePlayPause}
     onTimeUpdate={() => {
       const currentTime = audioPlayer1.current.currentTime;
       handleSeek('player1', currentTime); // Sync hidden player
     }}
   >
     Your browser does not support the audio element.
   </audio>
 
   <audio
     ref={audioPlayer2}
     src={mergedAudioUrl1}
     controls
     className="hidden"
   >
     Your browser does not support the audio element.
   </audio>
 
   <div className="flex flex-col items-center w-full mt-4">
     <div className="volume-controls flex flex-col md:flex-row justify-center items-center gap-5 w-full">
       
       {/* Volume Control for Soundtrack */}
       <div className="volume-control flex items-center gap-2">
         <label className="flex items-center gap-2">
           <span className="font-semibold">Adjust Volume (Soundtrack):</span>
         </label>
         <input
           type="range"
           min="0"
           max="100"
           value={volume1}
           onChange={handleVolumeChange1}
           className="w-full md:w-40 lg:w-56 mx-2"
         />
         <span className="volume-percentage min-w-[50px]">{volume1}%</span>
       </div>
 
       {/* Volume Control for Uploaded/Recorded */}
       <div className="volume-control flex items-center gap-2 mt-4 md:mt-0">
         <label className="flex items-center gap-2">
           <span className="font-semibold">Adjust Volume (Uploaded/Recorded):</span>
         </label>
         <input
           type="range"
           min="0"
           max="100"
           value={volume2}
           onChange={handleVolumeChange2}
           className="w-full md:w-40 lg:w-56 mx-2"
         />
         <span className="volume-percentage min-w-[50px]">{volume2}%</span>
       </div>
 
     </div>
   </div>
 </div>
 

      )}

      {mergedAudioUrl && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
<div className="max-w-xl mx-auto p-5 mb-2 bg-gradient-to-br from-white to-gray-50 rounded-xl shadow-lg border border-gray-200 mt-8 transition-all duration-300 ease-in-out">
  {/* Coupon Input */}
  <div className="mb-6 w-full">
    <label className="text-lg font-semibold text-gray-800 mb-2 block">
      Coupon:
      <span className="font-normal text-gray-500"> (Enter coupon code if you have one)</span>
    </label>
    <input
      type="text"
      placeholder="Enter coupon"
      value={couponCode}
      onChange={handleCouponChange}
      className="w-full p-3 rounded-lg text-sm border border-gray-300 focus:border-blue-400 focus:ring-blue-200 focus:ring-3 focus:outline-none transition duration-200 ease-in bg-gray-100 hover:bg-white"
    />
    {couponError && (
      <div className="text-red-500 mt-2 text-sm animate-pulse">{couponError}</div>
    )}
  </div>

  {/* Email Input */}
  <div className="mb-6 w-full">
    <label className="text-lg font-semibold text-gray-800 mb-2 block">
      Email:
      <span className="font-normal text-gray-500"> (We send the audio file to this email address)</span>
    </label>
    <input
      type="email"
      placeholder="Enter email"
      value={email}
      onChange={handleEmailChange}
      className="w-full p-3 rounded-lg text-sm border border-gray-300 focus:border-blue-400 focus:ring-blue-200 focus:ring-3 focus:outline-none transition duration-200 ease-in bg-gray-100 hover:bg-white"
    />
    {emailError && (
      <div className="flex items-center text-red-500 mt-2 text-sm animate-bounce">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 mr-1"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M18 8a8 8 0 11-16 0 8 8 0 0116 0zm-9 4a1 1 0 112 0v-2a1 1 0 00-2 0v2zm1-7a1 1 0 100 2 1 1 0 000-2z"
            clipRule="evenodd"
          />
        </svg>
        <span>Email is required</span>
      </div>
    )}
  </div>
</div>





          {/* {couponError && (
            <div style={{ color: 'red', marginBottom: '10px' }}>{couponError}</div>
          )} */}

          {/* {mergedAudioUrl && ( */}
          {/* <div style={{ marginTop: '20px', marginBottom: '30px' }}>
              <ReactAudioPlayer src={mergedAudioUrl} autoPlay={false} controls />
            </div> */}
          {/* )} */}
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            {isProcessing && (
              <div style={{
                color: '#555',
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '10px',
                animation: 'fadeIn 0.5s'
              }}>
                Please wait, it will take a bit time...
              </div>
            )}

            <button
              style={{
                background: 'linear-gradient(to right, #b5eaef, #ede38f, #f492ed)',
                color: 'black',
                padding: '5px 10px',
                borderRadius: '32px',
                cursor: isProcessing ? 'not-allowed' : 'pointer',
                width: '380px',
                fontWeight: 'bold',
                fontSize: '22px',
                opacity: isProcessing ? 0.5 : 1,
                pointerEvents: isProcessing ? 'none' : 'auto',
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
              }}
              onClick={handlepayment}
              disabled={isProcessing}
            >
              {isProcessing ? (
                <CustomLoader
                  size={30}
                  color="black"
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                />
              ) : (
                <>
                  <span style={{ flex: 1, textAlign: 'center' }}>Pay now and create full affirmation audio</span>
                  <FaCcPaypal size={40} style={{ marginLeft: '10px' }} />
                </>
              )}
            </button>
          </div>

        </div>
      )}



    </div>
  );
}

export default MergePlayAudio;
